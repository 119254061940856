import React, {useContext} from 'react';
import YouTube from 'react-youtube';
import {PlayerContext} from './App';

function VideoPlayer({url, startSeconds, play, mute, player, quality, globalCurrentTimeRef}) {
    const {playerReadyList, setPlayerReadyList} = useContext(PlayerContext);

    // Référence pour le player YouTube
    const playerRef = React.useRef(null);

    // Extraire l'ID de la vidéo YouTube à partir de l'URL
    const videoId = getYouTubeID(url);

    // Options pour le lecteur YouTube
    const opts = {
        width: '100%',
        height: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: play ? 1 : 0,
            start: startSeconds,
            mute: mute ? 1 : 0,
            controls: 0,
            modestbranding: 1,
            rel: 0,
            disablekb: 1,
            fs: 0,
            iv_load_policy: 3,
            loop: 1,
            showinfo: 0,
            cc_load_policy: 0,
            color: 'white',
            logo: 0,
        },
    };

    // Fonction pour extraire l'ID de la vidéo YouTube à partir de l'URL
    function getYouTubeID(url) {
        const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        if (match && match[2].length === 11) {
            return match[2];
        } else {
            // Gérer l'erreur ou retourner une valeur par défaut
            return url;
        }
    }

    const onReady = (event) => {
        playerRef.current = event.target; // Stocke une référence au player

        if (playerReadyList.length === 0 || !playerReadyList.includes(player)) {
            setPlayerReadyList([...playerReadyList, player]);
        }
    };

    function playVideoAtStart(delay) {
        // Gestion décalage d'un petit 10ème de seconde si délai avant lancement
        let seekToValue = startSeconds < 0 ? startSeconds - 0.099 : startSeconds;

        document.querySelectorAll('.nh').forEach(el => el.style.opacity = '1');
        setTimeout(function () {
            if (!mute) playerRef.current.mute();
            playerRef.current.playVideo();
            playerRef.current.setPlaybackQuality(quality);
            setTimeout(function () {
                if (!mute) playerRef.current.unMute();
                playerRef.current.seekTo(seekToValue, true);
                document.querySelectorAll('.nh').forEach(el => el.style.opacity = '0');
                setTimeout(function () {
                    document.querySelectorAll('.tv, .bv').forEach(el => {
                        el.style.transition = 'opacity 1s ease-out';
                        el.style.opacity = '0';
                    });
                }, 3000);
            }, 5000);
        }, delay);
    }

    function playVideoDuring(delay, currentTime) {
        // Si la vidéo a un délai avant lancement
        if (delay > 0) {
            // Si on est au début de la lecture
            if (globalCurrentTimeRef.current <= delay) {
                setTimeout(function () {
                    if (!mute) playerRef.current.mute();
                    playerRef.current.playVideo();
                    playerRef.current.setPlaybackQuality(quality);
                    setTimeout(function () {
                        if (!mute) playerRef.current.unMute();
                        playerRef.current.seekTo(startSeconds, true);
                        document.querySelectorAll('.nh').forEach(el => el.style.opacity = '0');
                    }, 5000);
                }, delay - globalCurrentTimeRef.current);
            } else {
                // Si on reprend la lecture après avoir cliqué sur STOP
                playerRef.current.playVideo();
            }
        } else {
            // Si on est au début de la lecture avec un démarrage > 0s
            if (currentTime === startSeconds * 1000) {
                document.querySelectorAll('.nh').forEach(el => el.style.opacity = '1');
                if (!mute) playerRef.current.mute();
                playerRef.current.playVideo();
                playerRef.current.setPlaybackQuality(quality);
                setTimeout(function () {
                    if (!mute) playerRef.current.unMute();
                    playerRef.current.seekTo(startSeconds, true);
                    document.querySelectorAll('.nh').forEach(el => el.style.opacity = '0');
                }, 5000);
            } else {
                // Si on reprend la lecture après avoir cliqué sur STOP
                playerRef.current.playVideo();
            }
        }
        setTimeout(function () {
            document.querySelectorAll('.tv, .bv').forEach(el => el.style.opacity = '0');
        }, 100);
    }

    function stopVideo(delay) {
        if (delay > 0) {
            if (globalCurrentTimeRef.current <= delay) {
                setTimeout(function () {
                    playerRef.current.pauseVideo();
                }, delay - globalCurrentTimeRef.current);
            } else {
                playerRef.current.pauseVideo();
            }
        } else {
            playerRef.current.pauseVideo();
        }
        document.querySelectorAll('.tv, .bv').forEach(el => el.style.opacity = '1');
    }

    // Effet pour gérer la lecture et l'arrêt de la vidéo
    React.useEffect(() => {
        if (!playerRef.current) return;

        let currentTime = playerRef.current.getCurrentTime() * 1000;

        /**
         * IMPORTANT : playerRef.current.getCurrentTime() ne renvoie que les entiers
         *
         * Extrait de la documentation :
         *  player.getCurrentTime():Number
         *  Cette fonction renvoie la durée écoulée en secondes depuis le début de la lecture de la vidéo.
         * Source : https://developers.google.com/youtube/iframe_api_reference?hl=fr#Functions
         */
        if (startSeconds > 0 && currentTime === 0) {
            currentTime += startSeconds * 1000;
        }
        const delay = startSeconds < 0 ? -startSeconds * 1000 : 0;
        if (delay === 0) {
            globalCurrentTimeRef.current = currentTime;
        }

        document.querySelectorAll('.tv, .bv').forEach(el => el.style.transition = 'opacity 0s');
        if (play) {
            if (currentTime === 0) {
                playVideoAtStart(delay);
            } else {
                playVideoDuring(delay, currentTime);
            }
        } else {
            stopVideo(delay);
        }
    }, [play]);

    return <YouTube videoId={videoId} opts={opts} onReady={onReady} className="h-full"/>;
}

export default VideoPlayer;
