import React, {useState, useEffect, createContext} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import ReactGA from 'react-ga4';
import VideoPlayer from './VideoPlayer';
import './index.css';

ReactGA.initialize('G-WWZM144PJM');

// Créez un contexte global pour stocker l'état des lecteurs vidéo
export const PlayerContext = createContext(undefined);

function App() {
    const globalCurrentTimeRef = React.useRef(null);

    const [videoLeft, setVideoLeft] = useState('');
    const [startLeft, setStartLeft] = useState(0);
    const [muteLeft, setMuteLeft] = useState(false);

    const [videoRight, setVideoRight] = useState('');
    const [startRight, setStartRight] = useState(0);
    const [muteRight, setMuteRight] = useState(false);

    const [additionalVideos, setAdditionalVideos] = useState([]);
    const [videoNew, setVideoNew] = useState('');
    const [play, setPlay] = useState(false);
    const [quality, setQuality] = useState('large');

    const [videosParams, setVideosParams] = useState(false);

    // Accédez à l'état global (contexte) pour savoir quels lecteurs sont prêts
    const [playerReadyList, setPlayerReadyList] = useState([]);

    const [videoHeight, setVideoHeight] = useState('85vh');

    const [playButtonDisabled, setPlayButtonDisabled] = useState(!videoLeft || !videoRight); // boutons PLAY / RESET désactivés si l'un des champs vidéo est vide

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    function checkIfIsMobile(paramsForMobile) {
        if (paramsForMobile && isMobile && !isTablet) {
            setQuality('small');

            // @TODO: message if is iphone ?
            // document.getElementById('landscape-message').style.display = 'none';
            // document.getElementById('mobile-message').style.display = 'block';
        }
    }

    function checkOrientation(newVideosLength = 0) {
        if (!isMobile && newVideosLength === 0 && window.innerHeight > window.innerWidth) {
            document.getElementById('landscape-message').style.display = 'block';
        } else {
            document.getElementById('landscape-message').style.display = 'none';
        }
    }

    function updateVideoHeight() {
        // const numberOfRows = Math.ceil(newVideos.length / 2); // 2 vidéos par ligne
        const screenHeight = window.innerHeight;
        // const calculatedHeight = screenHeight / numberOfRows;
        const calculatedHeight = screenHeight / 2 - 100;
        setVideoHeight(`${calculatedHeight}px`);
    }

    function setBackgroundColor(backgroundColor) {
        document.getElementById('AppId').style.backgroundColor = '#' + backgroundColor;
        let inputs = document.getElementsByClassName('input');
        for (let i = 0; i < inputs.length; i++) {
            inputs[i].style.backgroundColor = '#' + backgroundColor;
        }
        let videos = document.getElementsByClassName('relative');
        for (let i = 0; i < videos.length; i++) {
            videos[i].style.border = '#' + backgroundColor;
            videos[i].style.backgroundColor = '#' + backgroundColor;
        }
        document.querySelectorAll('.tv, .bv').forEach(el => {
            el.style.backgroundColor = '#' + backgroundColor;
            el.style.borderRadius = 0;
        });
    }

    function hideAddVideoButton() {
        document.getElementById('addVideoButton').style.display = 'none';
    }

    function hideAll() {
        hideAddVideoButton();
        let elements = document.querySelectorAll('input, label ,.seconds');
        for (let i = 0; i < elements.length; i++) {
            elements[i].style.display = 'none';
        }
    }

    const setVideo = (value, player) => {
        if (player === 'left') {
            setVideoLeft(value);
        }
        if (player === 'right') {
            setVideoRight(value);
        }
        if (document.getElementById('videoLeft').value === ''
            || document.getElementById('videoRight').value === ''
        ) {
            setPlayButtonDisabled(true);
        } else {
            setPlayButtonDisabled(false);
        }
    };

    // Fonction pour ajouter un champ de vidéo supplémentaire
    const addVideoInput = () => {
        const newVideos = [...additionalVideos, {url: '', startSeconds: 0}];
        setAdditionalVideos(newVideos);
        updateVideoHeight();
    };

    // Fonction pour mettre à jour les vidéos supplémentaires
    const updateAdditionalVideo = (index, value, field) => {
        const newVideos = [...additionalVideos];
        newVideos[index][field] = value;
        setAdditionalVideos(newVideos);
    };

    // Fonction pour ajouter une nouvelle vidéo
    const addVideo = () => {
        if (videoNew) {
            setAdditionalVideos([...additionalVideos, videoNew]);
            setVideoNew(''); // Réinitialiser le champ de saisie
        }
    };

    // Gérer le bouton de lecture/arrêt
    const handlePlayStop = () => {
        setPlay(!play);
    };

    useEffect(() => {
        if (playerReadyList.includes('left') && playerReadyList.includes('right')) {
            document.querySelectorAll('.nh').forEach(el => el.style.opacity = '0');
        }
    }, [playerReadyList]);

    // Fonction pour réinitialiser les vidéos
    const handleReset = () => {
        setPlayButtonDisabled(true);
        document.querySelectorAll('.nh').forEach(el => {
            el.style.transition = 'opacity 2s ease-out';
            el.style.opacity = '1';
        });

        setPlay(false); // Arrêter les vidéos

        // Réinitialiser les vidéos avec les valeurs actuelles de décalage
        if (videoLeft && videoRight) {
            setVideoLeft('');
            setVideoRight('');
            setTimeout(function () {
                setVideoLeft(videoLeft);
                setVideoRight(videoRight);
            }, 100);
            setTimeout(() => setVideoLeft(videoLeft), 100);
        }

        setAdditionalVideos(additionalVideos.map(video => ({...video, url: ''})));
        setTimeout(() => setAdditionalVideos(additionalVideos), 100);

        setTimeout(function () {
            document.querySelectorAll('.nh').forEach(el => el.style.opacity = '0');
            setTimeout(() => document.querySelectorAll('.nh').forEach(el => el.style.transition = 'opacity 0s'), 2000);
            setPlayButtonDisabled(false);
        }, 2000);

        document.querySelectorAll('.tv, .bv').forEach(el => {
            el.style.opacity = '1';
        });
    };

    function init(videosParams) {
        if (videosParams === true) {
            // Initial reset
            handleReset();
        } else {
            // document.querySelectorAll('.nh').forEach(el => el.style.opacity = '1');
            document.querySelectorAll('.nh').forEach(el => {
                // el.style.transition = 'opacity 3s ease-out';
                el.style.opacity = '0';
            });
            setTimeout(() => document.querySelectorAll('.nh').forEach(el => el.style.transition = 'opacity 2s ease-out'), 5000);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', checkOrientation);
        window.addEventListener('orientationchange', checkOrientation);

        // Récupérez les paramètres de recherche de l'URL de la fenêtre
        const queryParams = new URLSearchParams(window.location.search);

        // videoLeft
        const videoLeftParam = queryParams.get('videoLeft');
        const startLeftParam = Number(queryParams.get('startLeft'));
        const muteLeftParam = queryParams.get('muteLeft');
        if (videoLeftParam) setVideoLeft(videoLeftParam);
        if (startLeftParam) setStartLeft(startLeftParam);
        if (muteLeftParam) setMuteLeft(muteLeftParam);

        // videoRight
        const videoRightParam = queryParams.get('videoRight');
        const startRightParam = Number(queryParams.get('startRight'));
        const muteRightParam = queryParams.get('muteRight');
        if (videoRightParam) setVideoRight(videoRightParam);
        if (startRightParam) setStartRight(startRightParam);
        if (muteRightParam) setMuteRight(muteRightParam);

        if (videoLeftParam || videoRightParam) {
            setVideosParams(true);
        }

        const backgroundColorParam = queryParams.get('bgColor');
        if (backgroundColorParam) setBackgroundColor(backgroundColorParam);

        const addVideoParam = queryParams.get('AV');
        if (addVideoParam && addVideoParam === '0') hideAddVideoButton();

        const hideAllParam = queryParams.get('HA');
        if (hideAllParam && hideAllParam === '1') hideAll();

        // Gérer les vidéos additionnelles
        const newVideos = [];
        for (let i = 1; queryParams.get(`video${i}`); i++) {
            newVideos.push({
                url: queryParams.get(`video${i}`),
                startSeconds: Number(queryParams.get(`start${i}`) || 0),
                mute: queryParams.get(`mute${i}`),
                bgColor: '#' + backgroundColorParam || '#FFF',
                display: (hideAllParam && hideAllParam === '1') ? 'none' : 'block'
            });
        }
        if (newVideos.length > 0) {
            setAdditionalVideos(newVideos);
            updateVideoHeight();
            document.getElementById('landscape-message').style.display = 'none';
        }

        // Initial mobile checking
        checkIfIsMobile(backgroundColorParam && videoLeftParam && videoRightParam);

        // Initial orientation checking for mobile
        checkOrientation(newVideos.length);
        
        init(videoLeftParam !== null && videoRightParam !== null);
    }, []);

    return (
        <PlayerContext.Provider value={{playerReadyList, setPlayerReadyList}}>
            <div className="App bg-neutral-50" id="AppId">
                <div id="mobile-message"
                     className="hidden fixed left-0 right-0 w-auto h-full bg-gray-900 text-white text-center whitespace-normal text-2xl p-10 opacity-80 overflow-scroll">
                    <div className="py-6"><p>🇫🇷</p>C'est chouette le smartphone, mais ce site mérite un écran plus large
                        pour être pleinement apprécié... rendez-vous sur votre ordinateur ?
                    </div>
                    <p className="py-10">💻 🤩</p>
                    <div className="py-6"><p>🇬🇧</p>Smartphones are great, but this website deserves a larger screen to
                        be
                        fully appreciated... so why don't you go to your computer?
                    </div>
                </div>

                <div id="landscape-message"
                     className="hidden fixed left-0 right-0 w-auto h-full bg-gray-900 text-white text-center whitespace-normal text-2xl p-10 opacity-80 overflow-scroll">
                    <div className="py-8"><p>🇫🇷</p>Je vous assure qu'on en profite mieux en mode paysage, tournez votre
                        écran s'il vous plaît !
                    </div>
                    <p className="py-12">🎬 🤩</p>
                    <div className="py-8"><p>🇬🇧</p>I assure you it's best viewed in landscape mode, so please turn your
                        screen around!
                    </div>
                </div>

                <a href={"https://streettrumpet.info/videos"} title={"Go go go streettrumpet.info"} target={"_blank"}>
                    <div
                        className="absolute top-0 right-0 h-75 p-2 px-3 bg-gray-700 rounded-bl-xl text-xs text-white opacity-20 hover:bg-gray-900">#STREETTRUMPET
                    </div>
                </a>

                <div id="infobulle" className="absolute top-0 left-0 h-75 p-2 px-3">
                    <div className="group" onClick={() => setIsTooltipOpen(!isTooltipOpen)}>
                        <div
                            className="flex items-center justify-center w-6 h-6 rounded-full bg-gray-700 text-white cursor-pointer opacity-20 hover:bg-gray-900">
                            <span>?</span>
                        </div>
                        <div className={`${isTooltipOpen ? "block" : "hidden"} absolute top-2 left-10 mb-6 w-96`}>
                            <div className="text-sm text-justify text-white p-2 bg-gray-400 rounded">
                                <p className="p-1.5"><span className="pr-2">🇫🇷</span>Le concept : pouvoir synchroniser
                                    deux vidéos (ou plus) indépendantes et visibles
                                    en ligne sur Youtube — grâce à son API pardi !</p>
                                <p className="pl-10 text-blue-900 hover:text-blue-600"><a
                                    href={"https://play.streettrumpet.info/?videoLeft=pwnefUaKCbc&startLeft=15.8&videoRight=LY2tjBFK20c&startRight=0&muteRight=1"} title={"Enjoy !"} target={"_blank"}>Juste
                                    un exemple de ce qu'on peut faire 🤩<br/>Just one example of what we can do 🤩</a></p>
                                <p className="p-1.5"><span className="pr-2">🇬🇧</span>The concept: to synchronize two (or
                                    more) independent videos that can be viewed
                                    online on Youtube - thanks to its API, of course!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-center justify-center min-h-screen">
                    <div className="flex justify-around w-full p-10 md:p-5">
                        <div className="input input-bordered">
                            <button
                                id={'playButton'}
                                onClick={handlePlayStop}
                                disabled={playButtonDisabled} // Désactive le bouton si une des vidéos n'est pas chargée
                                className={`text-white text-xl font-bold py-2 px-6 m-1 transition-colors duration-200 rounded-full ${playButtonDisabled ? 'bg-gray-400' : 'bg-green-900 hover:bg-green-600'}`}
                            >
                                {play ? "STOP" : "PLAY"}
                            </button>
                            <button
                                onClick={handleReset}
                                disabled={playButtonDisabled}
                                className={`text-white text-xl font-bold py-2 px-6 m-1 transition-colors duration-200 rounded-full ${playButtonDisabled ? 'bg-gray-400' : 'bg-green-900 hover:bg-green-600'}`}>
                                RESET
                            </button>
                            <button id="addVideoButton" onClick={addVideoInput}
                                    className="text-white text-xl font-bold py-2 px-6 m-1 transition-colors duration-200 rounded-full bg-red-900 hover:bg-red-600">
                                ADD VIDEO
                            </button>
                            <button onClick={() => window.location.reload()}
                                    className="text-white text-xl font-bold p-2 m-1 transition-colors duration-200 transform rounded-full bg-blue-900 hover:bg-blue-600">
                                <svg className="w-[2rem] h-[1.4rem] inline" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 22"
                                     fill="currentColor">
                                    <path fillRule="evenodd"
                                          d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                                          clipRule="evenodd"/>
                                </svg>
                                {/*<span className="mx-1">RELOAD</span>*/}
                            </button>
                            {videosParams && (
                                <button id="addVideoButton"
                                        onClick={() => window.open('https://play.streettrumpet.info/', '_blank')}
                                        className="text-white text-xl font-bold py-2 ml-10 px-6 m-1 transition-colors duration-200 rounded-full bg-blue-900 hover:bg-blue-600">
                                    TRY
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Conteneurs pour les vidéos et les champs URL */}
                    <div className="flex flex-col w-full h-full md:flex-row">

                        {/* Cadre et champ URL pour la vidéo Left */}
                        <div className="flex-1">
                            <div className="flex flex-col items-center">
                                <div className="flex w-full items-center pl-4 pr-4">
                                    <input
                                        id="videoLeft"
                                        type="text"
                                        placeholder="Youtube URL / ID #left"
                                        value={videoLeft}
                                        onChange={(e) => setVideo(e.target.value, 'left')}
                                        className="input input-bordered w-auto max-w-md mb-2 mr-4 p-2 md:w-full"
                                    />
                                    <div className="flex w-full items-center mb-2">
                                        <label htmlFor="startLeft"
                                               className="w-full mr-2 text-right text-gray-400 md:w-auto">
                                            starts at
                                        </label>
                                        <input
                                            id="startLeft"
                                            type="number"
                                            step="0.01"
                                            value={startLeft}
                                            onChange={(e) => setStartLeft(e.target.value)}
                                            className="input input-bordered w-20 p-2 md:w-20"
                                        />
                                        <span
                                            className="seconds w-full mr-2 text-right text-gray-400 md:w-auto">second(s)</span>
                                    </div>
                                </div>
                                <div
                                    className="relative w-full bg-black border-2 border-gray-800 rounded-lg h-[85vh]"
                                    style={{height: videoHeight}}>
                                    <div className="tv bg-black rounded-t-lg"></div>
                                    {!videoLeft && (
                                        <div
                                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            <div className="text-gray-500">
                                                <p className="py-6 text-center text-6xl">🎞️</p>
                                                <p className="py-2"><span className="px-2">🇫🇷</span>Votre vidéo #left
                                                    apparaîtra ici</p>
                                                <p className="py-2"><span className="px-2">🇬🇧</span>Your #left video
                                                    will appear here</p>
                                            </div>
                                        </div>
                                    )}
                                    {videoLeft && (
                                        <VideoPlayer url={videoLeft} startSeconds={startLeft} play={play}
                                                     mute={muteLeft} player={'left'} quality={quality}
                                                     globalCurrentTimeRef={globalCurrentTimeRef}/>
                                    )}
                                    <div className="bv bg-black rounded-b-lg"></div>
                                    <div className="nh bg-black"><span className="loader"></span></div>
                                </div>
                            </div>
                        </div>

                        {/* Cadre et champ URL pour la vidéo Right */}
                        <div className="flex-1">
                            <div className="flex flex-col items-center">
                                <div className="flex w-full items-center pl-4 pr-4">
                                    <input
                                        id="videoRight"
                                        type="text"
                                        placeholder="Youtube URL / ID #right"
                                        value={videoRight}
                                        onChange={(e) => setVideo(e.target.value, 'right')}
                                        className="input input-bordered w-auto max-w-md mb-2 mr-4 p-2 md:w-full"
                                    />
                                    <div className="flex w-full items-center mb-2">
                                        <label htmlFor="startRight"
                                               className="w-full mr-2 text-right text-gray-400 md:w-auto">
                                            starts at
                                        </label>
                                        <input
                                            id="startRight"
                                            type="number"
                                            step="0.01"
                                            value={startRight}
                                            onChange={(e) => setStartRight(e.target.value)}
                                            className="input input-bordered w-20 p-2 md:w-20"
                                        />
                                        <span
                                            className="seconds w-full mr-2 text-right text-gray-400 md:w-auto">second(s)</span>
                                    </div>
                                </div>
                                <div
                                    className="relative w-full bg-black border-2 border-gray-800 rounded-lg h-[85vh]"
                                    style={{height: videoHeight}}>
                                    <div className="tv bg-black rounded-t-lg"></div>
                                    {!videoRight && (
                                        <div
                                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                            <div className="text-gray-500">
                                                <p className="py-6 text-center text-6xl">🎞️</p>
                                                <p className="py-2"><span className="px-2">🇫🇷</span>Votre vidéo #right
                                                    apparaîtra ici</p>
                                                <p className="py-2"><span className="px-2">🇬🇧</span>Your #right video
                                                    will appear here</p>
                                            </div>
                                        </div>
                                    )}
                                    {videoRight && (
                                        <VideoPlayer url={videoRight} startSeconds={startRight} play={play}
                                                     mute={muteRight} player={'right'} quality={quality}
                                                     globalCurrentTimeRef={globalCurrentTimeRef}/>
                                    )}
                                    <div className="bv bg-black rounded-b-lg"></div>
                                    <div className="nh bg-black"><span className="loader"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex w-full h-full mt-4 md:flex-row">
                        {/* Rendu des champs de vidéo supplémentaires */}
                        {additionalVideos.map((video, index) => (
                            <div key={`video-${index}`} className="flex-1">
                                <div className="flex flex-col items-center">
                                    <div className="flex w-full items-center pl-4 pr-4"
                                         style={{display: video.display}}>
                                        <input
                                            type="text"
                                            placeholder="Youtube URL / ID"
                                            value={video.url}
                                            onChange={(e) => updateAdditionalVideo(index, e.target.value, 'url')}
                                            className="input input-bordered w-full mb-2 mr-4 p-2 md:w-auto"
                                            style={{backgroundColor: video.bgColor}}
                                        />
                                        <div className="flex w-full items-center mb-2">
                                            <label htmlFor={"startNew" + index}
                                                   className="w-full mr-2 text-right text-gray-400 md:w-auto">
                                                starts at
                                            </label>
                                            <input
                                                id={"startNew" + index}
                                                type="number"
                                                step="0.01"
                                                value={video.startSeconds}
                                                onChange={(e) => updateAdditionalVideo(index, e.target.value, 'startSeconds')}
                                                className="input input-bordered w-auto p-2 md:w-20"
                                                style={{backgroundColor: video.bgColor}}
                                            />
                                            <span
                                                className="seconds w-full mr-2 text-right text-gray-400 md:w-auto">second(s)</span>
                                        </div>
                                    </div>
                                    <div
                                        className="relative w-full bg-black border-2 border-gray-800 rounded-lg h-[85vh]"
                                        style={{height: videoHeight, borderColor: video.bgColor}}>
                                        <div className="tv bg-black rounded-t-lg"></div>
                                        {!video.url && (
                                            <div
                                                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                <div className="text-gray-500">
                                                    <p className="py-6 text-center text-6xl">🎞️</p>
                                                    <p className="py-2"><span className="px-2">🇫🇷</span>Votre vidéo
                                                        apparaîtra ici</p>
                                                    <p className="py-2"><span className="px-2">🇬🇧</span>Your video will
                                                        appear here</p>
                                                </div>
                                            </div>
                                        )}
                                        {video.url && (
                                            <VideoPlayer url={video.url} startSeconds={video.startSeconds} play={play}
                                                         mute={video.mute} player={index} quality={quality}
                                                         globalCurrentTimeRef={globalCurrentTimeRef}/>
                                        )}
                                        <div className="bv bg-black rounded-b-lg"></div>
                                        <div className="nh"></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </PlayerContext.Provider>
    );

}

export default App;